import { stagger, useAnimate } from 'framer-motion';
import { useEffect } from 'react';

import { SvgIcon } from '@mui/material';

const ErrorSvg = () => {
	const [scope, animate] = useAnimate();

	useEffect(() => {
		animate('path', { pathLength: 1 }, { duration: 1, delay: stagger(0.2) });
	}, []);

	return (
		<SvgIcon
			sx={{
				width: '100%',
				height: '100%',
				aspectRatio: '214.6 / 70.6',
				stroke: 'currentColor',
				fill: 'transparent',
			}}
			ref={scope}
			viewBox="0 0 214.6 70.6"
			xmlns="http://www.w3.org/2000/svg">
			<path
				pathLength={0}
				d="M 0 0 L 40.9 0 L 40.9 1.1 L 1.2 1.1 L 1.2 33.9 L 34.9 33.9 L 34.9 35 L 1.2 35 L 1.2 68.9 L 41 68.9 L 40.9 70 L 0 70 L 0 0 Z"
				id="0"
				vectorEffect="non-scaling-stroke"
			/>
			<path
				pathLength={0}
				d="M 58 70 L 56.8 70 L 56.8 21 L 57.1 21 Q 57.8 21 57.8 21.8 L 57.95 35.4 A 35.385 35.385 0 0 1 59.248 31.655 A 27.628 27.628 0 0 1 60.55 28.925 A 20.114 20.114 0 0 1 62.673 25.771 A 16.937 16.937 0 0 1 64.2 24.15 Q 66.3 22.2 68.975 21.2 A 15.622 15.622 0 0 1 72.558 20.344 A 20.087 20.087 0 0 1 75 20.2 A 20.252 20.252 0 0 1 77.795 20.386 A 16.717 16.717 0 0 1 79.45 20.7 Q 81.5 21.2 83.4 22.1 L 83.2 22.5 A 8.082 8.082 0 0 0 83.106 22.644 A 5.881 5.881 0 0 0 83.025 22.775 Q 82.95 22.9 82.7 22.9 A 0.796 0.796 0 0 1 82.555 22.884 Q 82.318 22.84 81.914 22.667 A 7.632 7.632 0 0 1 81.875 22.65 Q 81.489 22.482 80.944 22.291 A 26.082 26.082 0 0 0 80.375 22.1 Q 79.667 21.87 78.724 21.67 A 26.637 26.637 0 0 0 78.125 21.55 A 13.325 13.325 0 0 0 76.875 21.379 Q 76.228 21.32 75.497 21.305 A 24.507 24.507 0 0 0 75 21.3 A 16.237 16.237 0 0 0 70.091 22.01 A 13.137 13.137 0 0 0 64.4 25.6 A 22.664 22.664 0 0 0 61.182 30.052 Q 59.932 32.272 58.925 35.006 A 46.292 46.292 0 0 0 58 37.8 L 58 70 Z"
				id="1"
				vectorEffect="non-scaling-stroke"
			/>
			<path
				pathLength={0}
				d="M 97.2 70 L 96 70 L 96 21 L 96.3 21 Q 97 21 97 21.8 L 97.15 35.4 A 35.385 35.385 0 0 1 98.448 31.655 A 27.628 27.628 0 0 1 99.75 28.925 A 20.114 20.114 0 0 1 101.873 25.771 A 16.937 16.937 0 0 1 103.4 24.15 Q 105.5 22.2 108.175 21.2 A 15.622 15.622 0 0 1 111.758 20.344 A 20.087 20.087 0 0 1 114.2 20.2 A 20.252 20.252 0 0 1 116.995 20.386 A 16.717 16.717 0 0 1 118.65 20.7 Q 120.7 21.2 122.6 22.1 L 122.4 22.5 A 8.082 8.082 0 0 0 122.306 22.644 A 5.881 5.881 0 0 0 122.225 22.775 Q 122.15 22.9 121.9 22.9 A 0.796 0.796 0 0 1 121.755 22.884 Q 121.518 22.84 121.114 22.667 A 7.632 7.632 0 0 1 121.075 22.65 Q 120.689 22.482 120.144 22.291 A 26.082 26.082 0 0 0 119.575 22.1 Q 118.867 21.87 117.924 21.67 A 26.637 26.637 0 0 0 117.325 21.55 A 13.325 13.325 0 0 0 116.075 21.379 Q 115.428 21.32 114.697 21.305 A 24.507 24.507 0 0 0 114.2 21.3 A 16.237 16.237 0 0 0 109.291 22.01 A 13.137 13.137 0 0 0 103.6 25.6 A 22.664 22.664 0 0 0 100.382 30.052 Q 99.132 32.272 98.125 35.006 A 46.292 46.292 0 0 0 97.2 37.8 L 97.2 70 Z"
				id="2"
				vectorEffect="non-scaling-stroke"
			/>
			<path
				pathLength={0}
				d="M 157.662 20.656 A 19.461 19.461 0 0 1 161.95 22.025 Q 165.95 23.85 168.675 27.15 A 21.123 21.123 0 0 1 171.796 32.337 A 26.262 26.262 0 0 1 172.8 35.125 Q 174.2 39.8 174.2 45.5 A 40.093 40.093 0 0 1 173.805 51.253 A 31.238 31.238 0 0 1 172.8 55.825 Q 171.4 60.45 168.675 63.75 Q 165.95 67.05 161.95 68.825 A 20.387 20.387 0 0 1 156.501 70.35 A 26.488 26.488 0 0 1 152.8 70.6 A 25.877 25.877 0 0 1 148.05 70.184 A 19.656 19.656 0 0 1 143.625 68.825 Q 139.65 67.05 136.925 63.75 Q 134.2 60.45 132.8 55.825 A 32.162 32.162 0 0 1 131.631 49.954 A 41.464 41.464 0 0 1 131.4 45.5 A 40.231 40.231 0 0 1 131.819 39.572 A 31.839 31.839 0 0 1 132.8 35.125 Q 134.2 30.45 136.925 27.15 Q 139.65 23.85 143.625 22.025 A 19.674 19.674 0 0 1 148.924 20.478 A 25.952 25.952 0 0 1 152.8 20.2 A 24.833 24.833 0 0 1 157.662 20.656 Z M 152.8 69.6 A 24.323 24.323 0 0 0 157.224 69.215 A 18.424 18.424 0 0 0 161.6 67.85 Q 165.4 66.1 167.925 62.95 A 20.045 20.045 0 0 0 170.78 58.067 A 25.106 25.106 0 0 0 171.725 55.35 Q 173 50.9 173 45.5 A 39.764 39.764 0 0 0 172.597 39.718 A 31.798 31.798 0 0 0 171.725 35.625 A 24.073 24.073 0 0 0 169.895 30.994 A 19.754 19.754 0 0 0 167.925 27.95 Q 165.4 24.75 161.6 22.975 A 18.566 18.566 0 0 0 156.523 21.471 A 24.608 24.608 0 0 0 152.8 21.2 A 24.272 24.272 0 0 0 148.381 21.584 A 18.012 18.012 0 0 0 143.975 22.975 Q 140.2 24.75 137.675 27.95 A 20.597 20.597 0 0 0 134.794 32.953 A 25.623 25.623 0 0 0 133.875 35.625 A 33.071 33.071 0 0 0 132.779 41.58 A 41.617 41.617 0 0 0 132.6 45.5 A 39.678 39.678 0 0 0 132.992 51.198 A 31.47 31.47 0 0 0 133.875 55.35 A 23.68 23.68 0 0 0 135.643 59.828 A 19.272 19.272 0 0 0 137.675 62.95 Q 140.2 66.1 143.975 67.85 Q 147.75 69.6 152.8 69.6 Z"
				id="3"
				vectorEffect="non-scaling-stroke"
			/>
			<path
				pathLength={0}
				d="M 189.2 70 L 188 70 L 188 21 L 188.3 21 Q 189 21 189 21.8 L 189.15 35.4 A 35.385 35.385 0 0 1 190.448 31.655 A 27.628 27.628 0 0 1 191.75 28.925 A 20.114 20.114 0 0 1 193.873 25.771 A 16.937 16.937 0 0 1 195.4 24.15 Q 197.5 22.2 200.175 21.2 A 15.622 15.622 0 0 1 203.758 20.344 A 20.087 20.087 0 0 1 206.2 20.2 A 20.252 20.252 0 0 1 208.995 20.386 A 16.717 16.717 0 0 1 210.65 20.7 Q 212.7 21.2 214.6 22.1 L 214.4 22.5 A 8.082 8.082 0 0 0 214.306 22.644 A 5.881 5.881 0 0 0 214.225 22.775 Q 214.15 22.9 213.9 22.9 A 0.796 0.796 0 0 1 213.755 22.884 Q 213.518 22.84 213.114 22.667 A 7.632 7.632 0 0 1 213.075 22.65 Q 212.689 22.482 212.144 22.291 A 26.082 26.082 0 0 0 211.575 22.1 Q 210.867 21.87 209.924 21.67 A 26.637 26.637 0 0 0 209.325 21.55 A 13.325 13.325 0 0 0 208.075 21.379 Q 207.428 21.32 206.697 21.305 A 24.507 24.507 0 0 0 206.2 21.3 A 16.237 16.237 0 0 0 201.291 22.01 A 13.137 13.137 0 0 0 195.6 25.6 A 22.664 22.664 0 0 0 192.382 30.052 Q 191.132 32.272 190.125 35.006 A 46.292 46.292 0 0 0 189.2 37.8 L 189.2 70 Z"
				id="4"
				vectorEffect="non-scaling-stroke"
			/>
		</SvgIcon>
	);
};

export default ErrorSvg;
